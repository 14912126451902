import { environment } from "src/environments/environment";
/**
 * dasa namespace contains all the classes used in dasa app
 */
export namespace dasa {
    export class DateWiseStoreCollection {
        storeId: number = -1;
        storeName: string = "";
        creationDate: string = "";
        NetPayable: number = 0.0;
        tax: number = 0.0;
        totalAmount: number = 0.0;
    }

    export class StoreWiseOrder {
        orderId: number = -1;
        storeId: number = -1;
        storeName: string = "";
        transactionId: string = "";
        orderIdOnSquareUp: string = "";
        amount: number = 0.0;
        tax: number = 0.0;
        total: number = 0.0;
        order: Order = {} as Order;

    }

    export class StoreWiseIncomeReport {
        storeId = -1;
        storeName = "";
        storeAdminId = -1;
        storeAdminName = "";
        noOfOrders = 0;

        onlinePaymentAmount = 0.0;
        offlinePaymentAmount = 0.0;
        totalAmount = 0.0;
    }

    export class BusinessWiseIncomeReport {
        businessId: number = -1;
        businessName: String = "";
        countryId: number = -1;
        countryName: String = "";
        currency: String = "";
        businessAdminId: number = -1;
        businessAdminName: String = "";
        noOfStores: number = 0;
        noOfOrders: number = 0;
        onlinePaymentAmount: number = 0.0;
        offlinePaymentAmount: number = 0.0;
        totalAmount: number = 0.0;
    }

    export class PaymentData {
        amount: number = 0;
        businessId: number = -1;
        storeId: number = -1;
        customerId: number = -1;
        paymentDataFromDevice: string = "";
        paymentMethodNounce: string = "";
        currency: string = "";

        // used in SquareUp only
        // sourceId, locationId, idemPotencyKey
        sourceId: string = "";
        locationId: string = "";
        idemPotencyKey: string = "";
    }

    /**
     * Method used for dashboard report
     */
    export class DashboardReport {
        storeCount: number = 0;
        customerCount: number = 0;
        orderCount: number = 0;
        totalEarning: number = 0;
        driverCount: number = 0;
        businessCount: number = 0;
        userCount: number = 0;
    }

    export class storeWiseSalesReport {
            storeAdminName: string = "";
            storeId: number = 0;
            storeName: string = "";
            totalBilledRevenue: number = 0;
            totalBrands: number = 0;
            totalCancelledAmount: number = 0;
            totalCancelledOrders: number = 0;
            totalCashAmount: number = 0;
            totalCashOrders: number = 0;
            totalDeliveredOrders: number = 0;
            totalDeliveryCharges: number = 0;
            totalDiscount: number = 0;
            totalNetPayable: number = 0;
            totalOnlineAmount: number = 0;
            totalOnlineOrders: number = 0;
            totalOrders: number = 0;
            totalPickUpOrders: number = 0;
            totalTax: number = 0;
            brands: number = 0;
            totalActiveCustomer: number = 0;
    }

    export class businessWiseSalesReport {
        businessAdminName: string = "";
        businessId: number = 0;
        businessName: string = "";
        countryId: number = 0;
        countryName: string = "";
        currency: string = "";
        numberOfStores: number = 0;
        totalBilledRevenue: number = 0;
        totalCancelledAmount: number = 0;
        totalCancelledOrders: number = 0;
        totalCashAmount: number = 0;
        totalCashOrders: number = 0;
        totalDeliveredOrders: number = 0;
        totalDeliveryCharges: number = 0;
        totalDiscount: number = 0;
        totalNetPayable: number = 0
        totalOnlineAmount: number = 0;
        totalOnlineOrders: number = 0;
        totalOrders: number = 0;
        totalPickUpOrders: number = 0;
        totalTax: number = 0;
        totalBrands: number = 0;
        totalActiveCustomer: number = 0;
}

export class superAdminSalesReport {
    totalBilledRevenue: number = 0;
    totalCancelledAmount: number = 0;
    totalCancelledOrders: number = 0;
    totalCashAmount: number = 0;
    totalCashOrders: number = 0;
    totalDeliveredOrders: number = 0;
    totalDeliveryCharges: number = 0;
    totalDiscount: number = 0;
    totalNetPayable: number = 0;
    totalOnlineAmount: number = 0;
    totalOnlineOrders: number = 0;
    totalOrders: number = 0;
    totalPickUpOrders: number = 0;
    totalTax: number = 0;
}
    /**
     *
     */
    export class ItemStoreRelation {
        itemId: number = -1;
        storeIds: number[] = [0];
    }

    export class QRCode {
        url: string = "";
        businessName: string = "";
        storeName: string = "";
        imageUrl: string = "";
        businessId: number = -1;
        storeId: number = -1;
    }
    /**
     * Business class
     */
    export class Business {
        id: number;
        businessAdminId: number;
        countryId: number;
        name: string;
        address: string;
        email: string;
        phone: string;
        status: number;
        logo: string;
        headerImage: string;

        createdOn: string;
        createdBy: number;
        modifiedOn: string;
        modifiedBy: number;

        constructor() {
            this.id = -1;
            this.businessAdminId = -1;
            this.countryId = -1;
            this.name = "";
            this.email = "";
            this.phone = "";
            this.status = Constant.STATUS_ENABLED;
            this.logo = "";
            this.headerImage = "";
            this.createdOn = "";
            this.createdBy = -1;
            this.modifiedOn = "";
            this.modifiedBy = -1;
            this.address = "";
        }
    }

    export class Customer {
        id = -1;
        businessId = -1;
        storeId = -1;
        name = "";
        email = "";
        phone = "";
        gender = Constant.GENDER_MALE;
        dob = "";
        password: string = "";
        pic: string = "";
        googleToken: string = "";
        facebookToken: string = "";
        stripToken: string = "";
        status: Number = Constant.STATUS_ENABLED;
        isMobileVerified: Number = Constant.IS_MOBILE_VERIFIED_NO;
        isEmailVerified: Number = Constant.IS_EMAIL_VERIFIED_NO;
        deliveryAddresses: DeliveryAddress[] = [] as DeliveryAddress[];
    }

    export class FeedbackComplain {
        id: number = -1;
        businessId: number = -1;
        storeId: number = -1;
        customerId: number = -1;
        type: number = -1;
        subject: string = "";
        content: string = "";
        status: number = Constant.FEEDBACK_STATUS_OPEN;
        dateTime: string = "";
        lastRespondedOn: string = "";

        conversations: FeedbackComplainConversation[] = [] as FeedbackComplainConversation[];
        customer: Customer = {} as Customer;
    }

    /**
     * Class Used to create an Object of FeedbackComplainConversation
     */
    export class FeedbackComplainConversation {
        id: number = -1;
        complainId: number = -1;
        content: string = "";
        customerId: number = -1;
        respondedBy: number = -1;
        status: number = Constant.FEEDBACK_STATUS_OPEN;
        dateTime: string = "";
    }

    /**
     * User class
     */
    export class User {
        id: number;
        businessId: number;
        storeId: number;
        name: string;
        userGroupId: number;
        phone: string;
        email: string;
        password: string;
        gender: string;
        dob: string;
        pic: string;
        status: number;
        isPhoneVerified: boolean;
        isEmailVerified: boolean;
        createdOn: string;
        createdBy: number;
        modifiedOn: string;
        modifiedBy: number;

        constructor() {
            this.id = -1;
            this.businessId = -1;
            this.storeId = -1;
            this.name = "";
            this.userGroupId = -1;
            this.phone = "";
            this.email = "";
            this.password = "";
            this.gender = Constant.GENDER_MALE;
            this.dob = "";
            this.pic = "";
            this.isPhoneVerified = false;
            this.isEmailVerified = false;
            this.status = Constant.STATUS_ENABLED;
            this.createdOn = "";
            this.createdBy = -1;
            this.modifiedOn = "";
            this.modifiedBy = -1;
        }
    }

    /**
     * UserGroup class
     * All stakeHolder of dasa cms belongs to some UserGroup
     * Every UserGroup will have module wise authority
     */
    export class UserGroup {
        id: number;
        name: string;
        constructor() {
            this.id = -1;
            this.name = "";
        }
    }
    export class PromoCode{
      id:number = 0;
      active: boolean = false;
      code:string ="";
      type:number = 0;
      value:number = 0;
      minimumCartSize:number = 0;
      businessId:number = 0;
      storeId:number = 0;
      startDate:string ="";
      endDate:string ="";
      maxRedemptions:number = 0;
      totalRedeemed:number = 0;
    }

    export class B1G1FreePromo{
      id:number = 0;
      promoCodesId:number = 0;
      active: boolean = false;
      code:string ="";
      promoItems: StoreItem[] = [];
      startDate:string ="";
      endDate:string ="";
      storeId:number = 0;

    }

    /**
     * Authority class
     * UserGroup's modulewise authority will decide that which module an user can access and at which level
     */
    export class Authority {


        id: number;
        name: string;
        constructor() {
            this.id = -1;
            this.name = "";
        }

    }
    /**
     * Module class
     */
    export class Module {
        id: number;
        name: string;
        constructor() {
            this.id = -1;
            this.name = ""
        }
    }
    /**
     * GroupAuthorization class
     */
    export class GroupAuthorization {
        id: number;
        storeId: number;
        moduleId: number;
        userGroupId: number;
        authorityId: number;

        constructor() {
            this.id = -1;
            this.storeId = -1;
            this.moduleId = -1;
            this.userGroupId = -1;
            this.authorityId = -1;
        }
    }


    export class Country {
        id: number;
        code: string;
        name: string;
        isd: string;
        currency: string;
        currencySymbol: string;

        constructor() {
            this.id = -1;
            this.code = "";
            this.name = "";
            this.isd = "";
            this.currency = "";
            this.currencySymbol = "";
        }
    }
    /**
     * Store Model Class uses to save and fetch store object
     */
    export class Store {
        id: number = -1;
        businessId: number = -1;
        storeAdminId: number = -1;
        deliveryType: number = Constant.DELIVERY_TYPE_PICKUP_DELIVERY_BOTH;
        name: String = "";
        email: String = "";
        phone: String = "";
        address: String = "";
        longitude: String = "";
        latitude: String = "";
        banner: String = "";
        description: String = "";
        adminCommision: String = "";
        defaultTax: String = "";
        isFeatured: number = Constant.FEATURED_STORE_NO;
        status: number = Constant.STATUS_ENABLED;
        vromoStoreId: String = "";
        createdBy: number = -1;
        createdOn: String = "";
        modifiedBy: number = -1;
        modifiedOn: String = "";
        clientVendorId: String = "";
        isRegisteredWithPandaGo = Constant.REGISTERED_WITH_PANDA_NO;
        pandaRegistrationDateTime = "";
    }
    /**
     *	TimingJson
     */
    export class TimingJson {
        day: string = "";
        openingTime: string = "";
        closingTime: string = "";
    }

    /**
     *	DeliveryRange
     */
    export class DeliveryRange {
        startRange: string = "";
        endRange: string = "";
        deliveryTime: string = "";

        id: number = -1;
	    deliverySettingId: number = -1;
	    charges: number = 0;
    }

    /**
     *	Brand
     */
    export class Brand {
        id: number = -1;
        businessId: number = -1;
        storeId: number = -1;
        name: string = "";
        pic: string = "";
        createdBy: number = -1;
        createdOn: string = "";
        modifiedBy: number = -1;
        modifiedOn: string = "";
        displayOrder: number = -1;
    }

    /**
      *	Category
      */
    export class Category {
        id: number = -1;
        businessId: number = -1;
        storeId: number = -1;
        name: string = "";
        pic: string = "";
        createdBy: number = -1;
        createdOn: string = "";
        modifiedBy: number = -1;
        modifiedOn: string = "";
        displayOrder: number = -1;
    }

    /**
     *	BankDetail
     */
    export class BankDetail {
        id: number = -1;
        businessId: number = -1;
        storeId: number = -1;
        name: string = "";
        bankName: string = "";
        ifscCode: string = "";
        accountNo: string = "";
        note: string = "";
        isVerified: number = -1;
        createdBy: number = -1;
        createdOn: string = "";
        modifiedBy: number = -1;
        modifiedOn: string = "";
    }

    /**
     * Class CMS
     */
    export class CMS {
        id: number = -1;
        type: number = Constant.CMS_TYPE_STORE;
        page: string = "";
        content: string = "";

        createdBy: number = -1;
        createdOn: string = "";
        modifiedBy: number = -1;
        modifiedOn: string = "";
    }

    /**
     * Class use to save and fetch store setting from database
     */
    export class StoreSettings {
        id: number = -1;
        storeId: number = -1;
        status: number = -1;
        storeDiscount: String = "";
        dRangeJson: DeliveryRange[] = [];
        timingJson: TimingJson[] = [];
        minOrderAmount: string = "";
        createdBy: number = -1;
        createdOn: string = "";
        modifiedBy: number = -1;
        modifiedOn: string = "";

        disabledBrandIds: number[] = [];
        disabledCategoryIds: number[] = [];
        disabledItemIds = [0];
    }

    /**
     * Constant class contain all the constant
     */
    export class Constant {
        public static readonly PAYMENT_TYPE_PAY_ONLINE = 1;
        public static readonly PAYMENT_TYPE_CASH_ON_DELIVERY = 2;

        public static FEEDBACK_TYPE_SUGGESTION = 1;
        public static FEEDBACK_TYPE_COMPLAIN = 2;
        public static FEEDBACK_TYPE_FEEDBACK = 3;

        public static FEEDBACK_STATUS_OPEN = 1;
        public static FEEDBACK_STATUS_CLOSED = 2;

        public static readonly IS_MOBILE_VERIFIED_YES = 1;
        public static readonly IS_MOBILE_VERIFIED_NO = 2;

        public static readonly IS_EMAIL_VERIFIED_YES = 1;
        public static readonly IS_EMAIL_VERIFIED_NO = 2;

        public static readonly DATE_TIME_FORMAT: string = "yyyy-MM-dd HH:mm:ss";
        public static readonly DISPLAY_DATE_TIME_FORMAT: string = "yyyy-MM-dd HH:mm";
        public static readonly DATE_SORT_FORMAT: string = "yyyy-MM-dd";

        public static readonly STATUS_ENABLED: number = 1;
        public static readonly STATUS_DISABLED: number = 2;

        public static readonly GENDER_MALE = "M";
        public static readonly GENDER_FEMALE = "F";
        public static readonly GENDER_TRANSGENDER = "T";

        public static readonly ACCESS_TYPE_NO_ACCESS: number = 1;
        public static readonly ACCESS_TYPE_READ_ONLY: number = 2;
        public static readonly ACCESS_TYPE_READ_WRITE: number = 3;

        public static readonly USER_TYPE_SUPER_ADMIN: number = 1;
        public static readonly USER_TYPE_BUSINESS_ADMIN: number = 2;
        public static readonly USER_TYPE_STORE_ADMIN: number = 3;
        public static readonly USER_TYPE_DRIVER: number = 4;
        public static readonly USER_TYPE_BUSINESS_ADMIN_cum_STORE_ADMIN: number = 5;
        public static readonly USER_TYPE_KITCHEN_USER: number = 6;

        public static readonly DELIVERY_TYPE_PICK_UP_ONLY: number = 1;
        public static readonly DELIVERY_TYPE_DELIVERY_ONLY: number = 2;
        public static readonly DELIVERY_TYPE_PICKUP_DELIVERY_BOTH: number = 3;

        public static readonly DELIVERY_CHARGE_PASS_ALONG_COSTS = 0;
	    public static readonly DELIVERY_CHARGE_CUSTOMER_SETTINGS = 1;

        public static readonly FEATURED_STORE_YES: number = 1;
        public static readonly FEATURED_STORE_NO: number = 2;

        public static readonly DISCOUNT_TYPE_PERCENT: number = 1;
        public static readonly DISCOUNT_TYPE_FLAT: number = 2;

        public static ORDER_TYPE_PENDING = 1;
        public static ORDER_TYPE_ACCEPTED = 2;
        public static ORDER_TYPE_REJECTED = 3;
        public static ORDER_TYPE_PROCESSING = 4;
        public static ORDER_TYPE_CANCELED = 5;
        public static ORDER_TYPE_PICKED = 6;
        public static ORDER_TYPE_DELIVERED = 7;
        public static ORDER_TYPE_READy_FOR_PICKUP = 8;
        public static ORDER_TYPE_OUT_FOR_DELIVERY = 9;
        public static ORDER_TYPE_SEARCHING_FOR_COURIER = 11;
        public static ORDER_TYPE_COURIER_ASSIGNED = 12;
        public static ORDER_TYPE_COURIER_ARRIVING_PICKUP = 13;
        public static ORDER_TYPE_CUSTOMER_DROPOFF_IMMINENT = 14;

        public static readonly ACTION_TYPE_ADD_EDIT_ORDER_OK = "addEditOrderOk";

        // public static readonly API_URL = "http://3.142.239.183/dasa/v1/api/requestHandler";
        // public static readonly FILE_UPLOAD_URL = "http://3.142.239.183/dasa/UploadFile";
        // public static readonly FILE_STREAM_URL = "http://3.142.239.183/dasa/streamer?name=";
        // public static readonly CMS_API_URL = "http://3.142.239.183/dasa/v1/api/customer/requestHandler";

        public static readonly API_URL = environment.hosturl;
        public static readonly FILE_UPLOAD_URL = environment.FILE_UPLOAD_URL;
        public static readonly FILE_STREAM_URL = environment.FILE_STREAM_URL;
        public static readonly CMS_API_URL = environment.CMS_API_URL;
        public static readonly API_REPORT_URL = environment.API_REPORT_URL;
        public static readonly WHAPI_URL = environment.WHAPI_URL;
        public static readonly WHAPI_BEARER_TOKEN = environment.WHAPI_BEARER_TOKEN;

        public static readonly ACTION_TYPE_LOGIN_OK = "loginOk";
        public static readonly ACTION_TYPE_ADD_USER_OK = "addUserOk";
        public static readonly ACTION_TYPE_ADD_BUSINESS_OK = "addBusinessOk";
        public static readonly ACTION_TYPE_EDIT_PROFILE_OK = "editProfileOk";
        public static readonly ACTION_TYPE_LOGOUT_OK = "logoutOk";
        public static readonly ACTION_TYPE_CHANGE_PASSWORD_OK = "changePasswordOk";

        public static readonly ACTION_TYPE_CHECK_PHONE_NUMBER_VALID= "isPhoneNumberValidOk";

        public static readonly ACTION_TYPE_ADD_SUPPORT_OK = "addSupportOk";
        public static readonly ACTION_TYPE_GET_SUPPORTS_OK = "getSupportsOk";
        public static readonly ACTION_TYPE_GET_SUPPORTS_BUSINESS = "getSupportsForBusiness";
        public static readonly ACTION_TYPE_DELETE_SUPPORT_OK = "deleteSupportOk";

        public static readonly ACTION_TYPE_GET_BUSINESS_SETTING = "getBusinessSettingOk";
        public static readonly ACTION_TYPE_ADD_STORE_OK = "addStoreOk";
        public static readonly ACTION_TYPE_ADD_STORE_ADMIN_OK = "addStoreAdminOk";

        public static readonly ACTION_TYPE_ADD_STORE_PREFERENCES_OK: string = "addStorePreferencesOk";
        public static readonly ACTION_TYPE_GET_STORE_PREFERENCES: string = "getStorePreferencesOk";

        public static readonly ACTION_TYPE_EDIT_CMS_OK: string = "editCmsOk";
        public static readonly ACTION_TYPE_GET_ALL_CMS: string = "getAllCmsOk";

        public static readonly ACTION_TYPE_GET_DRIVERS_OK = "getDriversOk";
        public static readonly ACTION_TYPE_ADD_DRIVERS_OK = "addEditDriverOk";

        public static readonly ACTION_TYPE_ADD_BANK_DETAIL_OK = "addBankDetailOk";
        public static readonly ACTION_TYPE_GET_BANK_DETAIL_OK = "getBankDetailOk";

        public static readonly ACTION_TYPE_ADD_BRAND_OK = "addBrandOk";
        public static readonly ACTION_TYPE_GET_BRANDS_OK = "getBrandsOk";
        public static readonly ACTION_TYPE_SHORT_BRAND_OK = "shortBrandsOk";

        public static readonly ACTION_TYPE_ADD_CATEGORY_OK = "addCategoryOk";
        public static readonly ACTION_TYPE_GET_CATEGORY_OK = "getCategoriesOk";
        public static readonly ACTION_TYPE_SHORT_CATEGORY_OK = "shortCategoriesOk";

        public static readonly ACTION_TYPE_ADD_STORE_ITEM_OK = "addStoreItemOk";
        public static readonly ACTION_TYPE_GET_STORE_ITEM_OK = "getStoreItemOk";

        public static readonly ACTION_TYPE_ADD_EXTRA_OK = "addExtraOk";
        public static readonly ACTION_TYPE_GET_EXTRA_OK = "getExtraOk";
        public static readonly ACTION_TYPE_SHORT_EXTRA_OK = "shortExtraAddOns";

        public static readonly ACTION_TYPE_GET_BUSINESS_BY_ID = "getBusinessById";

        public static readonly ACTION_TYPE_ADD_ITEM_EXTRA_RELATION_OK = "addItemExtraRelationOk";
        public static readonly ACTION_TYPE_GET_EXTRAS_BY_ITEMID_OK = "getExtrasByItemId";
        public static readonly ACTION_TYPE_GET_MY_STORE_SETTING = "getMySoreSettingOk";

        public static readonly ACTION_TYPE_ADD_BUSINESS_ITEM_OK = "addBusinessItemOk";
        public static readonly ACTION_TYPE_GET_BUSINESS_ITEM_OK = "getBusinessItemOk";
        public static readonly ACTION_TYPE_GET_CMS_BY_ID = "getCmsById";
        public static readonly ACTION_TYPE_GET_DASHBOARD_REPORT = "getDashboardReport";
        public static readonly ACTION_TYPE_ADD_BUSINESS_ITEM_TO_STORE = "addBusinessItemToStore";
        public static readonly ACTION_TYPE_GENERATE_QR_CODE = "generateQRCode";

        public static readonly ACTION_TYPE_GET_ORDER_FOR_STORE = "getOrderForStore";
        public static readonly ACTION_TYPE_GET_SCHEDULE_ORDER_FOR_STORE = "getPreOrderForStore";

        public static readonly ACTION_TYPE_CHANGE_STATUS_OF_AN_ORDER = "changeStatusOfAnOrder";
        public static readonly ACTION_TYPE_GET_CUSTOMER_BY_MOBILE_NO = "getCustomerByMobileNo";
        public static readonly ACTION_TYPE_GET_CUSTOMER_BY_ID = "getCustomerById";

        public static readonly ACTION_TYPE_ADD_COMPLAIN_FEEDBACK_OK = "addEditComplainFeedbackOk";

        public static readonly ACTION_TYPE_GET_COMPLAIN_FEEDBACK_FOR_STORE = "getComplainFeedbackForStore";
        public static readonly ACTION_TYPE_ADD_CONVERSATION_OK = "addEditConversationOk";

        public static readonly ACTION_TYPE_CREATE_PAYMENT_FROM_SQUAREUP: string = "getCreatePaymentFromSquareup";
        public static readonly ACTION_TYPE_RECOVER_PASSWORD_OK = "recoverPasswordOk";
        public static readonly CMS_TYPE_DRIVER: number = 1;
        public static readonly CMS_TYPE_STORE: number = 2;
        public static readonly CMS_TYPE_CUSTOMER: number = 3;
        public static readonly ACTION_TYPE_GET_BUSINESS_WISE_INCOME_REPORT = "getBusinessWiseIncomeReport";
        public static readonly ACTION_TYPE_GET_STORE_WISE_INCOME_REPORT = "getStoreWiseIncomeReport";
        public static readonly ACTION_TYPE_GET_DATE_WISE_STORE_COLLECTION = "getDateWiseStoreCollection";
        public static readonly ACTION_TYPE_GET_STORE_WISE_ORDERS = "getStoreWiseOrders";
        public static readonly ACTION_TYPE_REGISTERCUSTOMER_WITH_DELIVERYADDRESS = "registerCustomerWithDeliveryAddress";
        public static readonly ACTION_TYPE_CREATE_PAYMENT_FROM_STRIPE = "getCreatePaymentFromStripe";

        // pandago constants start
        public static readonly ACTION_TYPE_CREATE_OUTLET_ON_PANDA = "createOutletOnPanda";
        public static readonly ACTION_TYPE_GET_ORDER_DETAIL_FROM_PANDA = "getOrderDetailFromPanda";
        public static readonly ACTION_TYPE_GET_DELIVERY_ESTIMATION = "getDeliveryEstimation";

        public static readonly REGISTERED_WITH_PANDA_YES = 1;
        public static readonly REGISTERED_WITH_PANDA_NO = 2
        // pandago constants ends

        // manage option set constant start
        public static readonly ACTION_TYPE_ADD_EDIT_OPTION_SET_OK = "addEditOptionSetOk";
        public static readonly ACTION_TYPE_GET_OPTION_SET_BY_ID_OK = "getOptionSetOk";
        public static readonly ACTION_TYPE_GET_OPTION_SET_BY_ITEMID_OK = "getOptionSetByItemIdOk";
        public static readonly ACTION_TYPE_DELETE_OPTION_BY_OPTION_ID_OK = "deleteOptionByOptionId";
        public static readonly ACTION_TYPE_DELETE_OPTIONSET = "deleteOptionSet";
        // manage option set constant ends

        // manage options constant start
        public static readonly ACTION_TYPE_ADD_EDIT_OPTION_OK = "addEditOptionOk";
        public static readonly ACTION_TYPE_GET_OPTION_BY_ID_OK = "getOptionOk";
        public static readonly ACTION_TYPE_GET_OPTION_BY_OPTIONSET_ID_OK = "getOptionByOptionSetIdOk";
        // manage optins constant ends

        // notification
        public static readonly ACTION_TYPE_REGISTER_USER_NOTIFICATION = "registerUserNotification";

        // delivery setting constant start
        public static readonly ACTION_TYPE_ADD_EDIT_DELIVERY_SETTINGS = "addEditDeliverySettingsOk";
        public static readonly ACTION_TYPE_GET_DELIVERY_SETTINGS = "getDeliverySettingsOk";
        public static readonly ACTION_TYPE_GET_DELIVERY_RANGE = "getDeliverySettingRangeOk";
        public static readonly ACTION_TYPE_ADD_EDIT_DELIVERY_RANGE = "addEditDeliverySettingRangeOk";
        public static readonly ACTION_TYPE_DELETE_DELIVERY_RANGE = "deleteDeliverySettingRangeOk";
        // delivery setting constant ends

        //ui settings
        public static readonly ACTION_TYPE_GET_UI_SETTINGS = "getUiSettingsOk";
        public static readonly ACTION_TYPE_ADD_EDIT_UI_SETTINGS = "addEditUISettingsOk";

        // dashboard report data
        public static readonly ACTION_TYPE_GET_STORE_WISE_SALES_REPORT = "getStoreWiseSalesReport";
        public static readonly ACTION_TYPE_GET_BUSINESS_WISE_SALES_REPORT = "getBusinessWiseSalesReport";
        public static readonly ACTION_TYPE_GET_SUPERADMIN_WISE_SALES_REPORT = "getSuperAdminSalesReport";
        public static readonly ACTION_TYPE_GET_LIST_OF_REPORT_BY_BUSINESS_ID = "getListOfReportByBusinessId";
        public static readonly ACTION_TYPE_GET_LIST_OF_BUSINESS_FOR_SUPERADMIN = "getListOfBusinessForSuperAdmin";

        //promo codes
        public static readonly ACTION_TYPE_GET_PROMO_CODES_LIST = "getAllPromoCodes";
        public static readonly ACTION_TYPE_GET_ALL_CUSTOMERS = "getAllCustomers";

        // WHAPI
        public static readonly ACTION_TYPE_SEND_TEXT_MESSAGE = "messages/text";

        //buy 1 get 1 free promo
        public static readonly ACTION_TYPE_CREATE_UPDATE_B1G1_FREE_PROMO = "createOrUpdateB1G1FreePromo";
        public static readonly ACTION_TYPE_GET_B1G1_FREE_PROMO_BY_ID = "getB1G1FreePromoById";
        public static readonly ACTION_TYPE_GET_B1G1_FREE_PROMO_BY_PROMO_CODE_ID = "getB1G1FreePromoByPromoCodesId";
        public static readonly ACTION_TYPE_GET_ALL_B1G1_FREE_PROMO = "getAllB1G1FreePromos";
        public static readonly ACTION_TYPE_DELETE_B1G1_FREE_PROMO = "deleteB1G1FreePromoByPromoCodeId";

    }

    // delivery charge setting start from here
    export  class DeliverySettings {
        businessId: number = -1;
        deliveryCost: number = 0;
        maxRadius: number = 50;
        name: string = "default";
        settingType: number = DeliveryConstants.DELIVERY_CHARGE_PASS_ALONG_COSTS;
        storeId: number = -1;
        id: number = -1;
        json: string = "";
        DeliverySettingRange: DeliverySettingRange[] = {} as DeliverySettingRange[];
    }

   export class DeliveryConstants {
        public static readonly DELIVERY_CHARGE_PASS_ALONG_COSTS: number = 0;
        public static readonly DELIVERY_CHARGE_CUSTOMER_SETTINGS: number = 1;
    }

    export class DeliverySettingRange {
        id: number = -1;
        deliverySettingId: number = -1;
        startRange: number = 0;
        endRange: number = 0;
        charges: number = 0;
        json: string = "";
    }
    // delivery charge setting ends from here

    // get option set start
    export class OptionSet {
        id: number = -1;
        name: string = "";
        itemId: number = -1;
        status: number = -1;
        displayOrder: number = -1;
        condition: number = -1;
        customNumber: number = 0;
        json: any = [];
        variantName: string = "";
        variantType: number = 0;
        options: any = [];
    }
    // get option set ends here

    // get options start from here
    export class Option {
        id: number = -1;
        optionSetId: number = -1;
        name: string = "";
        price: number = -1;
        String: string = "";
        status: number = -1;
        displayOrder: number = -1;
        json: any = [];
        }
    // get options ends here

    // set & get ui settings start here
    export class UISettings {
        id: number = -1;
        storeId: number = -1;
        businessId: number = -1;
        createdBy = -1;
        header: string = "";
        footer: string = "";
        buttonSuccess: string = "";
        buttonWarning: string = "";
        buttonDanger: string = "";
        bodyText: string = "";
        primaryColor: string = "";
        secondaryColor: string = "";
        buttonSaveOk: string = "";
        cancelButton: string = "";
        checkOutColor: string = "";
        categoryButton: string = "";
        leftNavigationButton: string = "";
    }
    // set & get ui setting ends here

    /*get extra data*/
    export class Extra {
        id: number = -1;
        businessId: number = -1;
        storeId: number = -1;
        name: string = "";
        pic: string = "";
        price: number = 0.0;
        note: string = "";
        createdBy: number = -1;
        createdOn: string = "";
        modifiedBy: number = -1;
        modifiedOn: string = "";
        quantity: number = 0;
        displayOrder: number = -1;
    }

    /**
     * For all Support detail related purpose
     */
    export class SupportDetail {
        id: number = -1;
        location: string = "";
        email: string = "";
        phone: string = "";
        note: string = "";
        businessName?: string = "";
        createdBy: number = -1;
        createdOn: string = "";
        modifiedBy: number = -1;
        modifiedOn: string = "";
        constructor() {

        }
    }

    /*
    for adding store items
    */
    export class StoreItem {
        id: number = -1;
        brandId: number = -1;
        categoryId: number = -1;
        businessId: number = -1;
        storeId: number = -1;
        name: String = "";
        pic: String = "";
        price: number = 0.0;
        unitId: number = -1;
        discountType = Constant.DISCOUNT_TYPE_FLAT;
        discountedAmount: number = 0.0;
        tax: number = 0.0;
        billAmount: number = 0.0;
        ingredients: String = "";
        description: String = "";
        preparationTime: String = "";
        additionalInfo: String = "";
        dailyCapacity: number = -1;
        createdBy: number = -1;
        createdOn: String = "";
        modifiedBy: number = -1;
        modifiedOn: String = "";
        extras: any = [];
        quantity: number = 0;
        servingSize: String = '';
        // optionSets: any = [];
        optionSets: OptionSet[] = {} as OptionSet[];
    }

    export class ExtraItemRelation {
        id: number = -1;
        itemId: number = -1;
        createdBy: number = -1;
        extraIds: number[] = [];
        extras: any = [];
    }

    /**
     * For all request made on server, we have to pass json of RequestObject
     */
    export class RequestObject {
        command: string;
        storeAdminId: number = -1;
        user: any;
        business: any;
        loggedUserId: number;
        supportDetail: any;
        changePassword: any;
        businessId: number;
        categoriesDisplayOrderIds: any;
        brandsDisplayOrderIds: any;
        addOnsDisplayOrderIds: any;
        store: any;
        email: string = "";
        phoneNumber: String = "";
        storeId: number = -1;
        storeSetting: any = {};
        cms: any = {};
        order: Order = {} as Order;
        bankDetail: any = {};
        brand: any = {};
        category: any = {};
        storeItem: any = {};
        extra: any = {};
        extraItemRelation: any = {};
        itemId: number = -1;
        cmsId: number = -1;
        itemStoreRelation: any = {};
        appType: string = "cms";
        startDate: string = "";
        endDate: string = "";
        deliveryAddress: dasa.DeliveryAddress = {} as dasa.DeliveryAddress
        feedbackComplain: FeedbackComplain = {} as FeedbackComplain;
        conversation: FeedbackComplainConversation = {} as FeedbackComplainConversation;
        orderStatus: OrderStatusHistory = {} as OrderStatusHistory;
        paymentData: dasa.PaymentData = {} as dasa.PaymentData;
        customer: Customer = {} as Customer;
        customerId: number = -1;
        orderId = -1;
        pandaOrderId = "";
        // options
        optionSet: OptionSet = {} as OptionSet;
        option: Option = {} as Option;
        optionSetId = -1;
        optionId = -1;
        deliverySettingId = -1;
        deliverySettings: DeliverySettings = {} as DeliverySettings;
        deliverySettingRangeId = -1;
        deliverySettingRange: DeliverySettingRange = {} as DeliverySettingRange;
        uiSettings: UISettings = {} as UISettings;
        promoCodes: any;
        b1g1freepromo: B1G1FreePromo = {} as B1G1FreePromo;
        b1g1freepromoId:number = -1;
        promoCodeId:number = -1;
        constructor() {
            this.command = "";
            this.user = {};
            this.business = {};
            this.loggedUserId = -1;
            this.supportDetail = {};
            this.changePassword = {};
            this.businessId = -1;
            this.store = {};
            this.promoCodes = {};
        }
    }


    export class MyStoreSetting {
        users: User[] = [];
        stores: Store[] = [];
        storeSettings: any = {};
        brands: Brand[] = [];
        categories: Category[] = [];
        storeItems: StoreItem[] = [];
    }

    /**
     * For each request made, server will respond with ResponseObject
     */
    export class ResponseObject {
        command: string;
        success: boolean;
        message: string;
        businesses: any;
        business: any;
        businessAdmins: any;
        user: any;
        supportDetail: any;
        supportDetails: any;
        businessSettings: any = null;
        stores: any = null;
        store: any = null;
        cmsArray: any = null;
        storeSetting: any = null;
        drivers: any = [];
        bankDetails: any = []
        bankDetail: any = {};
        brands: any = [];
        brand: any = {};
        categories: any = [];
        category: any = {};
        storeItem: any = {};
        storeItems: any = [];
        extra: any = {};
        extras: any = [];
        extraItemRelation: any = {};
        myStoreSettings: any = {};

        businessItem: any = {};
        businessItems: any = [];
        cms: any = {};
        report: any = {};
        storeWiseSalesReport: any = {};

        businessWiseSalesReport: any = {};
        superAdminSalesReports: superAdminSalesReport[] = [];
        qrCode: any = {};
        optionSets: dasa.OptionSet = {} as dasa.OptionSet;
        option: dasa.Option = {} as dasa.Option;
        deliveryAddress: dasa.DeliveryAddress = {} as dasa.DeliveryAddress;
        feedbackComplain: FeedbackComplain = {} as FeedbackComplain;
        conversation: FeedbackComplainConversation = {} as FeedbackComplainConversation;
        feedbackComplains: FeedbackComplain[] = [] as FeedbackComplain[];
        orders: Order[] = [] as Order[];
        orderStatusHistories: OrderStatusHistory[] = [] as OrderStatusHistory[];
        paymentResponseJson: string = '{}';
        businessWiseIncomeReport: BusinessWiseIncomeReport[] = [] as BusinessWiseIncomeReport[];
        storeWiseIncomeReport: StoreWiseIncomeReport[] = [] as StoreWiseIncomeReport[];
        datewiseStoreCollections: DateWiseStoreCollection[] = [] as DateWiseStoreCollection[];
        storeWiseOrders: StoreWiseOrder[] = [] as StoreWiseOrder[];
        customer: Customer = {} as Customer;
        pandaOrderDetail: PandaOrderResponse = {} as PandaOrderResponse;
        deliveryCharge: DeliveryCharge = {} as DeliveryCharge;

        deliverySettingId: number = -1;
	      deliverySettings: dasa.DeliverySettings = {} as dasa.DeliverySettings;
        deliverySettingRange: dasa.DeliverySettingRange = {} as dasa.DeliverySettingRange;
        customers: Customer[] = [] as Customer[];
        promoCodes:PromoCode = {} as PromoCode;
        B1G1FreePromoCode: B1G1FreePromo = {} as B1G1FreePromo;
        b1g1FreePromoList: any = [];
        constructor() {
            this.command = "";
            this.success = false;
            this.message = "";
            this.businessAdmins = [];
            this.user = {};
            this.business = {};
            this.businesses = [];
            this.supportDetail = {};
            this.supportDetails = [];
        }
    }

    export class OrderStatusHistory {
        id: number = -1;
        orderId: number = -1;
        status: number = -1;
        updatedOn: string = "";
        updatedBy: number = -1;
        note: string = "";
    }

    export class DeliveryAddress {
        personName: string = "";
        personMobile: string = "";
        email: string = "";
        houseNumber: string = "";
        streatAddress: string = "";
        address: string = "";
        longitude: string = "";
        latitude: string = "";
        pincode: string = "";
        deliveryNote: string = "";
    }

    export class Order {
        id: number = -1;
        businessId: number = -1;
        storeId: number = -1;
        customerId: number = -1;
        carts: Cart[] = [] as dasa.Cart[];
        totalPrice: number = 0.0;
        totalExtraPrice: number = 0.0;
        totalDiscount: number = 0.0;
        netPayable: number = 0.0;
        deliveryCharges: number = 0.0;
        deliveryAddress: DeliveryAddress = {} as DeliveryAddress;
        status: number = Constant.ORDER_TYPE_PENDING;
        createdBy: number = -1;
        createdOn: string = "";
        orderStatusHistory: OrderStatusHistory[] = [] as OrderStatusHistory[];
        // fields that are missing in db call
        paymentType: number = Constant.PAYMENT_TYPE_CASH_ON_DELIVERY;
        tax: number = 0.0;
        modifiedBy: number = -1;
        modifiedOn: string = "";
        deliveryType: number = Constant.DELIVERY_TYPE_PICK_UP_ONLY;
        deliveryNote: string = "";
        transactionId: string = "";
        paymentId: number = -1;
        customer: Customer = {} as Customer;
        trackingUrl = "";
        pickupTime: string = "";

        pandaOrderId = "";
        pandaOrderDetail: PandaOrderResponse = {} as PandaOrderResponse;
        lastUpdatedFromPanda = "";
        totalOptionPrice: number = 0;
        discountONPromo: number = 0;
        scheduled: boolean = false;
        scheduledDateTime: string = '';
    }

    export class DeliveryCharge {
        estimated_delivery_fee = 0;
    }

    export class PandaOrderResponse {
        order_id = "";
        sender: Sender = {} as Sender;
        recipient: Receiver = {} as Receiver;
        payment_method = "";
        coldbag_needed = false;
        amount = 0;
        description = "";
        status = "";
        delivery_fee = 0;
        timeline: Timeline = {} as Timeline;
        driver: Driver = {} as Driver;
        created_at = -1;
        updated_at = -1;
        tracking_link = "";
        proof_of_delivery_url = "";
    }

    export class Driver {
        id = "";
        name = "";
        phone_number = "";
    }

    export class Timeline {
        estimated_pickup_time = "";
        estimated_delivery_time = "";
    }

    export class Receiver {
        name = "";
        phone_number = "";
        location = null;
        notes = "";
    }

    export class Sender {
        name = "";
        phone_number = "";
        location = null;
        notes = "";
    }
    export class Location {
        address = "";
        latitude = "";
        longitude = "";
        notes = "";
    }

    export class Cart {
        storeItem: StoreItem = {} as StoreItem;
        quantity: number = 0;
        price: number = 0;
        extraPrice: number = 0;
        tax: number = 0;
        discount: number = 0;
        netPayable: number = 0;
        optionPrice: number = 0.0
    }

    export class myReportData {
        businessId: number = -1;
        storeId: number = -1;
        startDate = "";
        endDate = "";
        currency = "";
        storeName = "";
        businessName = "";
    }
    /**
     * Utility class
     */
    export class Utility {
        public static customerId: number = -1;
        public static loggedInUser: any;
        public static socketId: string = "";
        public static latitude: number = -1;
        public static longitude: number = -1;
        public static countries: Country[] = [] as Country[];
        public static businesses: Business[] = [];
        public static supportDetails: SupportDetail[] = [];
        public static users: User[] = [];
        public static stores: Store[] = [];
        public static selectedBusinessId: number = -1;
        public static busyDivObject: any = {};
        public static storeItems: StoreItem[] = [];
        public static brands: any = [];
        public static categorys: any = [];
        public static selectedStoreId = -1;
        public static myReportData: myReportData = {} as myReportData;
        public static business: any = {};
        public static country: Country = {} as Country;
        public static storeSettings: any = {};
        public static complains: FeedbackComplain[] = [] as FeedbackComplain[];
        public static categories: any = [];
        public static businessItems: any = [];
        public static selectedCountry: Country = {} as Country;
        public static carts: Cart[] = [] as Cart[];
        public static storeOrders: Order[] = [] as Order[];
        public static temporaryOrderRequest: RequestObject = {} as RequestObject;
        public static selectedCustomerAddress: dasa.DeliveryAddress = {} as dasa.DeliveryAddress;
        public static categoriesDisplayOrderIds: any = [];
        public static brandsDisplayOrderIds: any = [];
        public static promoCodesList: any = [];
        public static b1g1FreePromoList: B1G1FreePromo[] =[];


        constructor() {
        }
        /**
         *
         * @returns Used to check if user has valid session
         */
        public static isUserLoggedIn(): boolean {
            if (this.loggedInUser != undefined && this.loggedInUser.id > 0)
                return true;
            else
                return false;
        }

        public static updateLoggedInUser(user: User) {
            Utility.loggedInUser = user;
        }

        //public static getStoreItemById():

        /**
         * Once business added successfuly, server return business object which should be added to businesses array
         * @param business
         */
        public static addBusiness(business: Business): void {
            // console.log('BusinessAdmins length before add :: ' + Utility.users.length);
            let isUserPresent = false;
            for (let i = 0; i < Utility.businesses.length; i++) {
                if (Utility.businesses[i].id * 1 == business.id * 1) {
                    isUserPresent = true;
                    Utility.businesses[i] = business;
                }
            }
            if (isUserPresent == false)
                Utility.businesses[Utility.businesses.length] = business;
        }

        /**
         * Once business added successfuly, server return business object which should be added to businesses array
         * @param business
         */
        public static addStoreItem(item: StoreItem): void {
            //console.log('BusinessAdmins length before add :: ' + Utility.users.length);
            let isUserPresent = false;
            for (let i = 0; i < Utility.storeItems.length; i++) {
                if (Utility.storeItems[i].id * 1 == item.id * 1) {
                    isUserPresent = true;
                    Utility.storeItems[i] = item;
                }
            }
            if (isUserPresent == false)
                Utility.storeItems[Utility.storeItems.length] = item;
        }

        /**
         * Once business added successfuly, server return business object which should be added to businesses array
         * @param business
         */
        public static addBusinessItem(item: StoreItem): void {
            // console.log('BusinessAdmins length before add :: ' + Utility.users.length);
            let isUserPresent = false;
            for (let i = 0; i < Utility.businessItems.length; i++) {
                if (Utility.businessItems[i].id * 1 == item.id * 1) {
                    isUserPresent = true;
                    Utility.businessItems[i] = item;
                }
            }
            if (isUserPresent == false)
                Utility.businessItems[Utility.businessItems.length] = item;
        }

        /**
         * Once a BusinessAdmin added successfuly, server return User object which should be added to businessAdmin array
         * Just a placeholder, later we have to replace businessAdmin if it is already present  in businessAdmins array
         * @param business
         */
        public static addBusinessAdmin(businessAdmin: User): void {
            // console.log('BusinessAdmins length before add :: ' + Utility.users.length);
            let isUserPresent = false;
            for (let i = 0; i < Utility.users.length; i++) {
                if (Utility.users[i].id * 1 == businessAdmin.id * 1) {
                    isUserPresent = true;
                    Utility.users[i] = businessAdmin;
                }
            }
            if (isUserPresent == false)
                Utility.users[Utility.users.length] = businessAdmin;
            // console.log('BusinessAdmins length after add :: ' + Utility.users.length);
        }

        /**
         * Once a store added successfuly, server return User object which should be added to businessAdmin array
         * Just a placeholder, later we have to replace businessAdmin if it is already present  in businessAdmins array
         * @param business
         */
        public static addStore(store: Store): void {
            //console.log('BusinessAdmins length before add :: ' + Utility.users.length ) ;
            let isUserPresent = false;
            for (let i = 0; i < Utility.stores.length; i++) {
                if (Utility.stores[i].id * 1 == store.id * 1) {
                    isUserPresent = true;
                    Utility.stores[i] = store;
                }
            }
            if (isUserPresent == false)
                Utility.stores[Utility.stores.length] = store;
        }

        public static addBrand(item: Brand): void {
            let isUserPresent = false;
            for (let i = 0; i < Utility.brands.length; i++) {
                if (Utility.brands[i].id * 1 == item.id * 1) {
                    isUserPresent = true;
                    Utility.brands[i] = item;
                }
            }
            if (isUserPresent == false)
                Utility.brands[Utility.brands.length] = item;
        }

        public static addCategory(item: Category): void {
            //console.log('BusinessAdmins length before add :: ' + Utility.users.length);
            let isUserPresent = false;
            for (let i = 0; i < Utility.categories.length; i++) {
                if (Utility.categories[i].id * 1 == item.id * 1) {
                    isUserPresent = true;
                    Utility.categories[i] = item;
                }
            }
            if (isUserPresent == false)
                Utility.categories[Utility.categories.length] = item;
        }

        /**
         * Used to show spinner
         */
        public static showBusy(): void {
            this.busyDivObject.style.display = '';
        }
        /**
         * Used to hide spinner
         */
        public static hideBusy(): void {
            this.busyDivObject.style.display = 'none';
        }
    }

    export class ChangePassword {
        userId: number = -1;
        oldPassword: string = '';
        newPassword: string = '';
        modifiedBy: number = -1;
        constructor() {
            // this.userId = -1;
            // this.oldPassword = "";
            // this.newPassword = "";
            // this.modifiedBy = -1;
        }
    }
}

export const environment = {
  mapbox: {
    accessToken: 'pk.eyJ1Ijoidml2ZWttaXNocmEtY3BsdXMiLCJhIjoiY2w2dnM1dTg3MDFlZzNicDdmdWxvOGNwOSJ9.8laNCJkgJbgRoh7bVa9xKg'
  },
  hosturl: 'https://dasasplace.com/dasa/v1/api/requestHandler',
  FILE_UPLOAD_URL: "https://dasasplace.com/dasa/UploadFile",
  FILE_STREAM_URL: "https://dasasplace.com/dasa/streamer?name=",
  CMS_API_URL: "https://dasasplace.com/dasa/v1/api/customer/requestHandler",
  dasa_order_url: "https://dasasplace.com/dasa/report/kot.jsp?orderId=",
  BILL_REPORT_URL: "https://dasasplace.com/dasa/report/bill.jsp?orderId=",
  API_REPORT_URL: "https://dasasplace.com/dasa/v1/api/reports/requestHandler",
  WEB_SOCKET_URL: "wss://dasasplace.com/dasa/websocket",
  NEW_WEB_SOCKET_URL: "wss://www.dasasplace.com/BackendDASA-1.0.0/ws",
  squareApplicationId: 'sandbox-sq0idb-jItZWLgr7Af5FR2pDppm6A',
  squareLocationId: 'LVKQTZ17XRNM5',
  PAYPAL_USERNAME: '',
  PAYPAL_PASSWORD: '',
  BROWSERSTACK_USERNAME: '',
  BROWSERSTACK_ACCESS_KEY: '',
  braintreeKey: 'sandbox_2493bdxv_d6f5fgqwpc93whsv',
  production: false,
  STRIPE_SECRET_KEY: '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',
  STRIPE_PUBLISHATION_KEY: 'pk_live_51M7gFiBkzC5Wbtnr2HskNcZeevKjZBfVMTS0NVPaKOaMV0VVuoy2Anhm3SrNmKeAZ3ycOHbgIvNQrSRf4Rys4Ve700Q7VLtl1f',
  WHAPI_URL:'https://gate.whapi.cloud/',
  WHAPI_BEARER_TOKEN:'6w51jtgQWbJ39tznG1WYku6UJOE2UTeB'
};
